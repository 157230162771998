import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import ProtectedRoute from './components/ProtectedRoute';

// Importação de Páginas
import Dashboard from './components/Dashboard';
import Relatorios from './components/Relatorios';
import Usuarios from './components/Usuarios';
import UsuarioDashboard from './components/UsuarioDashboard';
import Motoristas from './components/Motoristas';
import ConferenciaPlacas from './components/ConferenciaPlacas';
import Login from './components/Login';
import Status from './components/Status';
import ControleEmbarque from './components/ControleEmbarque';
import DashbordPrincipal from './components/DashbordPrincipal';
import ControleEntradaProduto from './components/ControleEntradaProduto';
import CadastroMotorista from './components/CadastroMotorista';
import CadastroProdutor from './components/CadastroProdutor';
import StatusInterno from './components/StatusInterno';
import StatusExterno from './components/StatusExterno';

function App() {
  const [menuAberto, setMenuAberto] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Detecta o subdomínio atual e redireciona
    const hostname = window.location.hostname;
    if (hostname === 'interno.coopergraos.com.br') {
      navigate('/status-interno');
    } else if (hostname === 'externo.coopergraos.com.br') {
      navigate('/status-externo');
    }
  }, [navigate]);

  return (
    <Routes>
      {/* Rotas públicas (NÃO mostram sidebar nem header) */}
      <Route path="/" element={<Status />} />
      <Route path="/login" element={<Login />} />
      <Route path="/status-interno" element={<StatusInterno />} />
      <Route path="/status-externo" element={<StatusExterno />} />

      {/* Rotas protegidas (MOSTRAM sidebar e header) */}
      <Route element={<ProtectedRoute />}>
        <Route
          path="/*"
          element={
            <Box sx={{ display: 'flex', width: '100%', minHeight: '100vh' }}>
              <Sidebar menuAberto={menuAberto} setMenuAberto={setMenuAberto} />
              <Box
                sx={{
                  flexGrow: 1,
                  transition: 'margin 0.3s',
                  marginLeft: menuAberto ? '260px' : '80px',
                  width: '100%',
                }}
              >
                <Header />
                <Box sx={{ padding: 3, backgroundColor: '#f5f5f5', minHeight: 'calc(100vh - 64px)' }}>
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/DashbordPrincipal" element={<DashbordPrincipal />} />
                    <Route path="/relatorios" element={<Relatorios />} />
                    <Route path="/usuarios" element={<Usuarios />} />
                    <Route path="/usuario-dashboard" element={<UsuarioDashboard />} />
                    <Route path="/motoristas" element={<Motoristas />} />
                    <Route path="/conferencia-placas" element={<ConferenciaPlacas />} />
                    <Route path="/ControleEmbarque" element={<ControleEmbarque />} />
                    <Route path="/ControleEntrada" element={<ControleEntradaProduto />} />
                    <Route path="/CadastroMotorista" element={<CadastroMotorista />} />
                    <Route path="/CadastroProdutor" element={<CadastroProdutor />} />
                  </Routes>
                </Box>
              </Box>
            </Box>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
