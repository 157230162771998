import React, { useEffect, useState, useRef, useMemo } from 'react';
import axios from 'axios';
import { Grid, Paper, Typography, Box, Container } from '@mui/material';
import Chart from 'chart.js/auto';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Dashboard = () => {
  const [totalMotoristas, setTotalMotoristas] = useState(0);
  const [totalProdutores, setTotalProdutores] = useState(0);
  const [statusEmbarque, setStatusEmbarque] = useState({});
  const [rankingProdutos, setRankingProdutos] = useState([]);

  // Caso queira utilizar os valores memoizados posteriormente:
  const statusEmbarqueLabels = useMemo(() => Object.keys(statusEmbarque), [statusEmbarque]);
  const statusEmbarqueValues = useMemo(() => Object.values(statusEmbarque), [statusEmbarque]);

  const barChartRef = useRef(null);
  const pieChartRef = useRef(null);
  const barChartInstance = useRef(null);
  const pieChartInstance = useRef(null);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  useEffect(() => {
    if (Object.keys(statusEmbarque).length > 0) {
      renderBarChart();
      renderPieChart();
    }

    // Cleanup: destrói as instâncias dos gráficos antes de atualizar ou desmontar o componente
    return () => {
      if (barChartInstance.current) {
        barChartInstance.current.destroy();
        barChartInstance.current = null;
      }
      if (pieChartInstance.current) {
        pieChartInstance.current.destroy();
        pieChartInstance.current = null;
      }
    };
  }, [statusEmbarque]);

  const fetchDashboardData = async () => {
    try {
      const motoristasRes = await axios.get('https://api-motoristas.coopergraos.com.br/dashboard.php?action=count_motoristas');
      setTotalMotoristas(motoristasRes.data.total_motoristas);

      const produtoresRes = await axios.get('https://api-motoristas.coopergraos.com.br/dashboard.php?action=count_produtores');
      setTotalProdutores(produtoresRes.data.total_produtores);

      const embarqueRes = await axios.get('https://api-motoristas.coopergraos.com.br/dashboard.php?action=controle_embarque_status');
      setStatusEmbarque(embarqueRes.data.status_controle_embarque || {});

      const rankingRes = await axios.get('https://api-motoristas.coopergraos.com.br/dashboard.php?action=controle_entrada_ranking');
      setRankingProdutos(rankingRes.data.ranking_produtos || []);
    } catch (error) {
      console.error('Erro ao buscar dados do dashboard:', error);
    }
  };

  const commonChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1500,
      easing: 'easeOutQuart',
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: { size: 14 },
          color: '#333'
        }
      },
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#333',
        bodyColor: '#333',
        borderColor: '#ccc',
        borderWidth: 1,
      }
    },
  };

  const renderBarChart = () => {
    if (barChartInstance.current) {
      barChartInstance.current.destroy();
    }
    if (barChartRef.current && Object.keys(statusEmbarque).length > 0) {
      barChartInstance.current = new Chart(barChartRef.current, {
        type: 'bar',
        data: {
          labels: Object.keys(statusEmbarque),
          datasets: [
            {
              label: 'Status do Controle de Embarque',
              data: Object.values(statusEmbarque),
              backgroundColor: ['#34a853', '#fbbc05', '#ea4335'],
              borderColor: '#fff',
              borderWidth: 1,
              borderRadius: 5,
            },
          ],
        },
        options: {
          ...commonChartOptions,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: '#333',
                font: { size: 12 },
              },
              grid: { color: '#e0e0e0' }
            },
            x: {
              ticks: {
                color: '#333',
                font: { size: 12 },
              },
              grid: { display: false },
            }
          }
        },
      });
    }
  };

  const renderPieChart = () => {
    if (pieChartInstance.current) {
      pieChartInstance.current.destroy();
    }
    if (pieChartRef.current && Object.keys(statusEmbarque).length > 0) {
      pieChartInstance.current = new Chart(pieChartRef.current, {
        type: 'pie',
        data: {
          labels: Object.keys(statusEmbarque),
          datasets: [
            {
              label: 'Distribuição de Status',
              data: Object.values(statusEmbarque),
              backgroundColor: ['#34a853', '#fbbc05', '#ea4335'],
              borderColor: '#fff',
              borderWidth: 1,
            },
          ],
        },
        options: { ...commonChartOptions },
      });
    }
  };

  return (
    <>
      <Header />
      <Sidebar />

      <Box
        sx={{
          marginLeft: { xs: 0, sm: '1px' }, // Ajuste conforme a largura do seu Sidebar
          marginTop: '64px', // Altura do Header (ajuste se necessário)
          padding: 3,
          backgroundColor: '#f5f5f5',
          minHeight: 'calc(100vh - 64px)'
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Paper elevation={3} sx={{ padding: 3, textAlign: 'center', minHeight: 150, borderRadius: 2 }}>
                <Typography variant="h6">Total de Motoristas</Typography>
                <Typography variant="h4" sx={{ color: '#34a853', fontWeight: 'bold' }}>
                  {totalMotoristas}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Paper elevation={3} sx={{ padding: 3, textAlign: 'center', minHeight: 150, borderRadius: 2 }}>
                <Typography variant="h6">Total de Produtores</Typography>
                <Typography variant="h4" sx={{ color: '#4285F4', fontWeight: 'bold' }}>
                  {totalProdutores}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Paper elevation={3} sx={{ padding: 3, textAlign: 'center', minHeight: 150, borderRadius: 2 }}>
                <Typography variant="h6">Total de Embarques</Typography>
                <Typography variant="h4" sx={{ color: '#FF5722', fontWeight: 'bold' }}>
                  {Object.values(statusEmbarque).reduce((a, b) => a + b, 0)}
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3} justifyContent="center" sx={{ marginTop: 3 }}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 3, minHeight: 350, borderRadius: 2 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Status do Controle de Embarque
                </Typography>
                <Box sx={{ position: 'relative', height: '250px' }}>
                  <canvas ref={barChartRef} />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 3, minHeight: 350, borderRadius: 2 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Distribuição de Status
                </Typography>
                <Box sx={{ position: 'relative', height: '250px' }}>
                  <canvas ref={pieChartRef} />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Ranking de Produtos
                </Typography>
                <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                  <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                    {rankingProdutos.slice(0, 5).map((item, index) => (
                      <li
                        key={index}
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          color: '#1b893b',
                          marginBottom: '8px'
                        }}
                      >
                        {index + 1}. {item.produto} ({item.quantidade})
                      </li>
                    ))}
                  </ul>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default Dashboard;
