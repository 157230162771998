import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Drawer, Divider } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ReportIcon from '@mui/icons-material/Report';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.css'; // Arquivo CSS separado para melhor organização

const drawerWidth = 260;

const Sidebar = () => {
  const navigate = useNavigate();
  const [nivelAcesso, setNivelAcesso] = useState(null);

  useEffect(() => {
    const acesso = parseInt(localStorage.getItem('nivelAcesso'), 10);
    setNivelAcesso(acesso);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('nivelAcesso');
    navigate('/login');
  };

  return (
    <Drawer
      variant="permanent"
      className="sidebar"
      PaperProps={{ className: 'sidebar-paper' }}
    >
      {/* Logo */}
      <div className="sidebar-logo">
        <img 
          src="https://athix.com.br/img/bg_cooperfibra.png" 
          alt="Logo" 
          className="sidebar-logo-image" 
        />
      </div>

      <Divider />

      {/* Lista de menus */}
      <List>
        <ListItem button component={Link} to="/dashboard" className="sidebar-item">
          <ListItemIcon className="sidebar-icon">
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Painel" />
        </ListItem>

        <ListItem button component={Link} to="/CadastroMotorista" className="sidebar-item">
          <ListItemIcon className="sidebar-icon">
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastro de Motorista" />
        </ListItem>

        <ListItem button component={Link} to="/CadastroProdutor" className="sidebar-item">
          <ListItemIcon className="sidebar-icon">
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastro Produtor" />
        </ListItem>

        <ListItem button component={Link} to="/ControleEmbarque" className="sidebar-item">
          <ListItemIcon className="sidebar-icon">
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText primary="Controle de Embarque" />
        </ListItem>

        <ListItem button component={Link} to="/ControleEntrada" className="sidebar-item">
          <ListItemIcon className="sidebar-icon">
            <PlaylistAddCheckIcon />
          </ListItemIcon>
          <ListItemText primary="Controle de Entrada" />
        </ListItem>

        {nivelAcesso !== 2 && (
          <ListItem button component={Link} to="/usuarios" className="sidebar-item">
            <ListItemIcon className="sidebar-icon">
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Usuários" />
          </ListItem>
        )}

        <ListItem button component={Link} to="/relatorios" className="sidebar-item">
          <ListItemIcon className="sidebar-icon">
            <ReportIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios" />
        </ListItem>

        {/* Botão de Logout */}
        <ListItem button onClick={handleLogout} className="sidebar-item logout-item">
          <ListItemIcon className="sidebar-icon">
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
