import React, { useEffect, useState } from 'react';
import {
  Container, Grid, TextField, Button, Paper, Typography, IconButton
} from '@mui/material';
import { Print as PrintIcon } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import dayjs from 'dayjs';

const Relatorios = () => {
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [tipoRelatorio, setTipoRelatorio] = useState('geral');
  const [relatorios, setRelatorios] = useState([]);

  const fetchRelatorios = async () => {
    try {
      const response = await axios.get(`https://api-motoristas.coopergraos.com.br/relatorios-geral.php?action=relatorio_${tipoRelatorio}`, {
        params: { dataInicial, dataFinal },
      });

      const formattedData = response.data.map(item => ({
        ...item,
        data: dayjs(item.data).format('DD/MM/YYYY'),
      }));

      setRelatorios(formattedData);
    } catch (error) {
      console.error('Erro ao buscar relatórios:', error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'data', headerName: 'Data', width: 150 },
    { field: 'motorista', headerName: 'Motorista', width: 200 },
    { field: 'produtor', headerName: 'Produtor', width: 200 },
    { field: 'produto', headerName: 'Produto', width: 200 },
    { field: 'status', headerName: 'Status', width: 150 },
  ];

  return (
    <>
      <Container sx={{ padding: 5 }}>
        <Typography variant="h4" sx={{ my: 4, textAlign: 'center' }}>
          Relatórios
        </Typography>

        {/* 📌 Filtros */}
        <Paper sx={{ p: 4, mb: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Data Inicial"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={dataInicial}
                onChange={(e) => setDataInicial(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Data Final"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={dataFinal}
                onChange={(e) => setDataFinal(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={fetchRelatorios}
              >
                Pesquisar
              </Button>
            </Grid>
          </Grid>
        </Paper>

        {/* 📌 Tabela */}
        <Paper sx={{ height: 500, width: '100%', mb: 4 }}>
          <DataGrid rows={relatorios} columns={columns} pageSize={10} />
        </Paper>

        {/* 📌 Botão de impressão */}
        <Grid container justifyContent="flex-end">
          <IconButton color="primary" onClick={handlePrint}>
            <PrintIcon />
          </IconButton>
        </Grid>
      </Container>
    </>
  );
};

export default Relatorios;
