import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Snackbar, Alert, TextField, Button, CircularProgress
} from '@mui/material';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        'https://api-motoristas.coopergraos.com.br/valida.php', 
        { email, senha },
        { headers: { 'Content-Type': 'application/json' } }
      );

      console.log("🟢 Resposta do backend:", response.data);

      if (response.data.token) {
        console.log("✅ Token recebido:", response.data.token);

        // Salvando token no localStorage
        localStorage.setItem('authToken', response.data.token);
        localStorage.setItem('userProfile', JSON.stringify(response.data));
        
        setSuccessMessage('Login bem-sucedido! Redirecionando...');
        
        setTimeout(() => {
          navigate('/dashboard'); 
        }, 1500);
      } else {
        console.log("❌ Erro no login:", response.data.message);
        setErrorMessage(response.data.message || 'Usuário ou senha inválidos');
      }
    } catch (error) {
      console.error('❌ Erro ao fazer login:', error);
      setErrorMessage('Erro ao conectar com o servidor');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <img src="https://athix.com.br/img/bg_cooperfibra.png" id="icon" alt="Logo" />
        </div>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Usuário"
            variant="outlined"
            className="fadeIn second"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Senha"
            type="password"
            variant="outlined"
            className="fadeIn third"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            required
            sx={{ marginBottom: 2 }}
          />

          <Button
            type="submit"
            variant="contained"
            color="success"
            className="fadeIn fourth"
            disabled={loading}
            fullWidth
            sx={{ padding: 1.5 }}
          >
            {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Entrar'}
          </Button>
        </form>

        <Snackbar open={Boolean(errorMessage)} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <Snackbar open={Boolean(successMessage)} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default Login;
